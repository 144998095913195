@import 'styles/variables';

.textareaDefault {
  position: relative;
  textarea::-webkit-outer-spin-button,
  textarea::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
  }

  textarea[type=number] {
    -moz-appearance: textfield;
  }

  textarea {
    resize: none;
  }

  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }


  label {
    font-size: $input-label-size;
    color: $input-label;
    display: block;
    padding-bottom: 0.31rem;
  }

  .textarea {
    padding: 0.625rem;
    border: 1px solid $input-border;
    border-radius: $border-radius;
    font-size: 1rem;
    font-weight: 400;
    color: $input-text;
    background-color: $input-bg;
    background-clip: padding-box;
    display: block;
    width: 100%;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &::placeholder {
      color: $input-placeholder;
      font-size: 0.8rem;
    }
  }
}