@import 'styles/variables';

.common {
  table {
    margin: 0;
    border: none;
    border-spacing: 0;
    // table-layout: fixed;
    text-align: left;
    border-collapse: collapse;
    border-spacing: 0;
  }

  thead {
    z-index: 1;

    th {
      padding: 0.625rem;
      color: $color-bright;
      text-transform: uppercase;
      font-size: 0.9375rem;
    }
  }

  tbody tr {
    color: $color-secondary;
    padding: 0.625rem 0;

    &:hover {
      background: $bg-primary;
    }

    td {
      padding: 0.31rem 0.625rem;
      color: $color-secondary;
      font-size: 0.9375rem;
      cursor: pointer;
    }
  }
  .noCursor td {
    cursor: default;
  }
  :global {
    .havePopup {
      width: 1rem !important;
    }
  }
}
.inlineAction {
  tbody tr td {
    cursor: initial;
  }
}
.error {
  background-color: $bg-error;
  border: 2px solid $bg-secondary;
}

.tbWrapper {
  background: $bg-secondary;
  overflow-x: auto;
}
.link a {
  text-decoration: underline;
  cursor: pointer;
}
.bordered {
  @extend .common;

  tbody tr {
    td {
      border: 1px solid $border-color;
    }
  }

  thead {
    background: $bg-primary;

    th {
      border: 1px solid $border-color;
    }
  }
}

.list {
  @extend .common;

  tbody tr {
    td {
      border-bottom: 1px solid $border-color;
    }
  }

  thead {
    background: $bg-secondary;

    th {
      border-bottom: 1px solid $border-color;
      padding: 0 0.625rem 0.625rem 0.625rem;
    }
  }
}

.statusBtn {
  border-radius: 5px;
  padding: 0.5rem;
  color: $color-light;
  min-width: 100px;
  font-size: 0.8rem;
}
.btnAdded {
  background: #1890ff;
}
.btnActive {
  background: #3fa33f;
}
.btnInActive {
  background: white;
  border: 1px solid #adadad;
  color: #535454;
}
.btnNew {
  background: #d60000;
}
.btnView {
  background: pink;
}
.btnDeclined {
  background: purple;
}
