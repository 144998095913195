@import 'styles/variables';

.global {
  :global {
    .react-datepicker {
      border: 1px solid $border-color;
      color: $color-primary;
    }

    .react-datepicker-popper {
      z-index: 10 !important;
    }

    .react-datepicker__input-container {
      z-index: 3;
      background: $bg-primary;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      height: $input-min-height;
    }

    .react-datepicker__close-icon {
      padding: 0;
      right: 35px;
      z-index: 4;
      margin-right: 0;
      height: 100%;
      &::after {
        cursor: pointer;
        background-color: $bg-primary;
        color: $color-primary;
        border-radius: 0;
        height: 26px;
        width: 26px;
        padding: 0;
        font-size: 20px;
        line-height: 1;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        content: '×';
      }
    }

    .react-datepicker__header {
      padding: 0;
      background-color: $bg-secondary;
    }

    .react-datepicker__current-month {
      color: $color-bright;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $bg-primary;
    }

    .react-datepicker__day--keyboard-selected {
      border-radius: 50%;
      background-color: $bg-bright;
      color: $color-light;
    }

    .react-datepicker__day-names {
      margin-bottom: 0;
    }

    .react-datepicker-popper {
      z-index: 3;
    }

    .react-datepicker-time__header {
      color: $bg-bright;
      padding: 5px;
    }

    .react-datepicker__triangle {
      display: none;
    }
  }
}

.defaultPicker {
  @extend .global;
  margin-bottom: $input-margin-bottom;
  position: relative;
  width: 100%;

  label {
    text-align: left;
    display: block;
    padding-left: 5px;
    color: $input-label;
    padding-bottom: 5px;
    font-size: 0.9rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield;
    /* Firefox */
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  input {
    width: 100%;
    border-radius: $border-radius;
    padding: 0 0.625rem;
    border: none;
    height: 100%;
    font-size: 1rem;
    background: $bg-secondary;
    color: $input-text;

    &::placeholder {
      color: $input-placeholder;
      font-size: 0.9rem;
    }

    &:disabled {
      cursor: not-allowed;
      background: $bg-secondary;
      opacity: 0.5;
    }

    &:active,
    &:-internal-autofill-selected {
      outline: none;
      background-color: $input-bg !important;
      background: $input-bg !important;
    }

    &:focus {
      outline: none;
    }
  }

  .picker {
    position: relative;
    > div {
      width: 100%;
    }
  }

  .icon {
    position: absolute;
    right: 1px;
    background: $bg-primary;
    top: 1px;
    z-index: 3;
    height: 92%;
    width: $input-min-height;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    svg {
      color: $color-bright;
      font-size: 1.2rem;
    }
  }

  &.disabled {
    :global {
      .react-datepicker__input-container {
        background-color: $bg-secondary;
        opacity: 0.5;
      }
      .react-datepicker__close-icon {
        display: none;
      }
    }
    .icon {
      display: none;
    }
  }
}
.defaultPickerNext {
  @extend .defaultPicker;
  display: flex;
  label {
    padding: 0;
  }
  > div {
    flex-grow: 1;
  }
}
.timePicker {
  @extend .defaultPicker;

  :global {
    .react-datepicker__time-container {
      width: 150px;
    }

    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 150px;
    }

    .react-datepicker--time-only .react-datepicker__triangle {
      display: none;
    }
  }

  input {
    width: 100%;
  }
}

.preview {
  @extend .defaultPicker;

  &.disabled {
    :global {
      .react-datepicker__input-container {
        border: none;
        border-bottom: 1px solid $border-color;
        border-radius: 0;
        font-weight: bold;
        background-color: transparent;
        opacity: 1;
      }

      .react-datepicker__close-icon {
        display: none;
      }

      input {
        font-weight: bold;
        padding: 0;
        background-color: transparent;
        opacity: 1;
      }

      label {
        padding-left: 0;
      }
    }

    .icon {
      display: none;
    }
  }
}

.defaultPickerYear {
  @extend .defaultPicker;
  :global {
    .react-datepicker-year-header {
      padding: 0.5rem;
      background-color: $bg-bright;
      color: $color-light;
    }
    .react-datepicker__year-text {
      padding: 0.3rem;
    }
    .react-datepicker__year-wrapper {
      justify-content: center;
    }
    .react-datepicker__year-text--keyboard-selected,
    .react-datepicker__year-text--selected {
      padding: 0.3rem;
      background-color: $bg-bright;
      color: $color-light;
    }
    .react-datepicker__navigation-icon::before {
      border-color: $bg-secondary;
    }
  }
}

.defaultPickerMont {
  @extend .defaultPicker;
  :global {
    .react-datepicker__header {
      color: $color-bright;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $bg-primary;
    }
    .react-datepicker__month-text {
      width: 6rem;
      font-size: 1rem;
    }
    .react-datepicker__month-text--keyboard-selected {
      border-radius: 0.3rem;
      background-color: $bg-bright;
      color: #fff;
      padding: 4px 0px;
    }
    .react-datepicker__month-text--keyboard-selected:hover {
      background-color: $bg-bright;
      color: #fff;
    }
  }
}

.defaultMultiPicker {
  @extend .defaultPicker;
  :global {
    .rmdp-container {
      width: 100%;
    }
    .rmdp-input {
      padding: 0 0.625rem;
      height: 30px;
      border: 1px solid #e5e5e5;
      border-radius: 0.31rem;
      font-size: 1rem;
      font-weight: 400;
      color: #4d4d4d;
      background-color: #ffffff;
      background-clip: padding-box;
      display: block;
      width: 100%;
    }
    .rmdp-header-values {
      color: $bg-bright;
      font-weight: bold;
      font-size: 0.944rem;
    }
    .rmdp-header {
      background: #f9f9f9;
    }
    .rmdp-arrow {
      margin-top: 7px;
      border: solid $bg-bright;
      border-width: 0 2px 2px 0;
    }
    .rmdp-day.rmdp-today span {
      background-color: $bg-bright;
    }
    .rmdp-arrow-container {
      &:hover {
        background-color: $bg-bright;
      }
    }
  }
}

.noYearPicker {
  @extend .defaultPicker;
  :global {
    .react-datepicker__day--selected {
      border-radius: 50%;
      background-color: $bg-bright;
      color: $color-light;
    }

    .customHeaderDatePicker {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f0f0f0;
      padding: 10px;

      button {
        color: #ccc;
        border: none;
        // padding: 5px 10px;
        cursor: pointer;
        font-size: 20px;

        &:hover {
          // background-color: #0056b3;
          color: $darkGray;
        }
      }

      span {
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }
}

.customInput {
  input {
    padding: 0 0.625rem;
    height: 30px;
    border: 1px solid #e5e5e5;
    border-radius: 0.31rem;
    font-size: 1rem;
    font-weight: 400;
    color: #4d4d4d;
    background-color: #ffffff;
    background-clip: padding-box;
    display: block;
    width: 100%;
  }
}
