@import 'styles/variables';

.wrapper {
  .loaderContainer {
    height: 85%;
    width: 100%;
    background-color: rgba($white, 0.8);
    z-index: 100;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    z-index: 99;
  }
  .btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0.5rem;
    b {
      margin: 1rem 0;
    }
    button {
      max-width: 250px;
      width: 100%;
    }
    .first {
      background-color: $bg-success;
      width: 100%;
      padding: 0.7rem;
      text-align: center;
      margin-bottom: 1rem;
    }
    .second {
      background-color: #ff900021;
      width: 100%;
      padding: 0.7rem;
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  .radioGroup {
    margin: 1rem 0;
    .radioGroupInputs {
      display: flex;
      position: relative;
    }
    p {
      margin-right: 1rem;
    }
    font-weight: bold;
    :global {
      .g-error-msg {
        top: 23px;
        left: 20px;
      }
    }
  }
  :global {
    .Klijent {
      background: $bg-success;
      padding: 0.5rem 0.5rem 0rem 0.5rem;
      label {
        color: $color-bright;
      }
    }
    .checkbox-pdv {
      width: 100px !important;
      position: relative;
      //bottom: -5px;
      padding-left: 5px;
    }
  }
  .between {
    display: none;
  }
  @media only screen and (min-width: 992px) {
    .radioGroup {
      display: flex;
      align-items: center;
    }
    .between {
      width: 20px !important;
      display: flex;
      align-items: center;
      justify-self: center;
      font-size: 25px;
      color: $color-secondary;
      box-sizing: border-box;
      padding: 3px;
      position: relative;
      top: 3px;
      margin-top: 1rem;
      left: 2px;
    }
    .border {
      border: 1px solid $border-color;
      border-radius: $border-radius;
      padding: 0.5rem;
      margin-bottom: 1rem;
    }
    .price {
      :global {
        .Valuta {
          margin-left: 10px;
        }
      }
    }
    .fca {
      :global {
        .checkbox-fcaKupac {
          width: 100px !important;
          padding-left: 5px;
          //  bottom: -5px;
          position: relative;
        }
        .FrankoUtovarenoGrad {
          width: calc(100% - 100px) !important;
        }
      }
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    > div {
      width: 49%;
    }
  }
}
.upload {
  position: relative;
  min-height: 200px;
}
.realization {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    width: 150px;
  }
  b {
    font-size: 0.8rem;
    color: $color-warning;
    font-weight: bold;
    text-decoration: underline;
    margin-top: 1rem;
    padding-left: 0.5rem;
    flex-grow: 1;
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
  }
}
.customSelect {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: $bg-primary;
  }

  span {
    font-size: 1rem;
    color: $input-text;
  }
}
.customOption {
  padding: 0.3rem 0.5rem;
  cursor: pointer;
}
