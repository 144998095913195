@import 'styles/variables';

.selectGlobal {
  margin-bottom: $input-margin-bottom;
  width: 100%;
  position: relative;

  :global {
    .selectCustom {
      outline: none;
    }
    .selectGlobal__value-container {
      position: relative;
      padding: 0 0.3rem;
    }

    .selectGlobal__container:focus,
    .selectGlobal__container:focus-visible,
    .selectGlobal__container:focus-within,
    .selectGlobal__container:active {
      outline: none;
    }
    .selectGlobal__indicators {
      height: 25px;
    }
    .selectGlobal__control {
      background-color: $bg-secondary;
      min-height: $input-min-height;
      height: auto;
      cursor: pointer;
      box-shadow: none;
      border-radius: $border-radius;
      border: 1px solid $input-border;
      outline: none;
      &:focus {
        outline: none !important;
      }
    }

    .selectGlobal--is-disabled {
      pointer-events: auto;
      .selectGlobal__placeholder {
        margin: 0;
        color: $color-primary;
      }
    }

    .selectGlobal__control--is-disabled {
      cursor: not-allowed;
      background: inherit;
      opacity: 0.5;
      background-color: $bg-secondary;
      &:hover {
        border-bottom: 1px solid $border-color;
      }
      .selectGlobal__dropdown-indicator {
        display: none;
      }
    }
    .selectGlobal__single-value {
      color: $input-text;
      font-size: small;
      font-style: 'normal';
      font-weight: 'regular';
      font-size: 1rem;
    }

    .selectGlobal__option {
      text-align: left;
      color: $input-text;
      font-size: 1rem;
      cursor: pointer;
      font-weight: 'regular';
      font-family: $font-primary;
      background: $white;

      &:active {
        background: $bg-light;
        // color: pink;
      }

      &:hover {
        background: $bg-light;
        color: $color-bright;
      }
    }

    .selectGlobal__menu {
      position: absolute;
      width: 100%;
      background: $bg-secondary;
      z-index: 4;
      border-radius: $border-radius;
    }

    .selectGlobal__menu-list {
      padding-top: 0;
      padding-bottom: 0;
      font-size: small;
      background: $bg-secondary;
      overflow: auto;
      max-height: 250px;
      overflow-x: hidden;
      border-radius: $border-radius;

      &:hover {
        background: $bg-secondary;
      }
    }

    .selectGlobal__indicator-separator {
      display: none !important;
    }

    .selectGlobal__dropdown-indicator {
      color: $color-primary;

      &:hover {
        opacity: 0.5;
      }
    }

    .selectGlobal__placeholder {
      color: $input-placeholder;
      font-family: $font-primary;
      font-size: 0.8rem;
      margin-left: 10px;
      font-size: 1rem;
    }

    label {
      color: $input-label;
      font-size: $input-label-size;
      margin-bottom: 5px;
      display: flex;
    }
    .selectGlobal__multi-value--is-disabled .selectGlobal__multi-value__remove {
      display: none;
    }
  }

  &.preview {
    :global {
      .selectGlobal__multi-value--is-disabled .selectGlobal__multi-value__remove {
        display: none;
      }
      .selectGlobal__control--is-disabled {
        background: inherit;
        opacity: 1;
        border: none;
        border-bottom: 1px solid $border-color;
        border-radius: 0;
        outline: none;
        font-weight: bold;
        &:hover {
          border-bottom: 1px solid $border-color;
        }

        .selectGlobal__value-container {
          padding: 0;
        }
        .selectGlobal__dropdown-indicator {
          display: none;
        }
      }
      .selectGlobal--is-disabled {
        pointer-events: auto;
        .selectGlobal__placeholder {
          margin: 0;
          color: $color-primary;
        }
      }
    }
  }
}
.selectPrimary {
  @extend .selectGlobal;
  width: 100%;
  position: relative;
}
.selectLabelNext {
  @extend .selectGlobal;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  label {
    padding-right: 1rem;
    margin-bottom: 0;
  }

  > div {
    flex-grow: 2;
  }
}
