@import 'styles/variables.scss';
.wrapper {
  max-width: 850px;
  width: 100%;
  margin: 5rem auto;
  h2 {
    color: $color-bright;
    border-bottom: 1px solid $border-color;
    padding-bottom: 3px;
    text-transform: uppercase;
    margin-bottom: 2rem;
    padding-bottom: 0.5rem;
  }
  .btn {
    width: 200px;
    margin: 0 auto;
    width: 200px;
  }
  a{
    color: $color-bright;
  }
}
