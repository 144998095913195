@import 'styles/variables';

.wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 1rem 0;
  border-bottom: 1px solid $border-color;
  flex-wrap: wrap;
  .button {
    // width: 20%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
  }
  .fields {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    .field {
      min-width: 200px;
      width: 20%;
      max-width: 200px;
    }
  }
  .range {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 992px) {
      display: flex;
      width: 40%;
      min-width: 400px;
      max-width: 400px;
      flex-direction: row;
    }
  }
}
