@import 'styles/variables';

.preview {
  display: flex;
  align-items: center;
  // justify-content: center;

  img {
    width: 3rem;
    height: auto;
    object-fit: cover;
  }

  .img {
    cursor: pointer;
  }

  .noImg {
    cursor: default;
  }
}

.imagesModal {
  height: 100%;

  .grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .imgBox {
      width: 48%;
      height: 15.625rem;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      overflow: hidden;
      padding: 0.5rem;
      margin-bottom: 2rem;
      cursor: pointer;
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-fit: contain;
      }

      :global {
        .noImage {
          width: 100px;
          height: auto;
        }
      }
    }
  }

  .oneImage {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    height: 100%;

    img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
    }

    @media only screen and (min-width: 768px) {

      min-width: 30rem;
      min-height: 30rem;
    }
  }
}

.singleImg {
  width: 100%;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: 0.5rem;
  position: relative;
  min-width: inherit;
  min-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  :global {
    .noImage {
      width: 100px;
      height: auto;
    }
  }

  .prev,
  .next {
    position: absolute;
    top: calc(50% - 1.25rem);
    width: 2.5rem;
    height: $input-min-height;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: $bg-secondary;
    border: 1px solid $border-color;
    border-radius: 50%;

    svg {
      font-size: 1.5rem;
      color: $color-secondary;
    }
  }

  .prev {
    left: -1.25rem;
  }

  .next {
    right: -1.25rem;
  }

  @media only screen and (min-width: 768px) {
    min-width: 30rem;
    min-height: 30rem;
  }
}