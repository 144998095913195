@import 'styles/variables';

.container {
  padding: 2rem;
  background: $bg-primary;
}

.sheredWrapper {
  border-radius: $border-radius;
  overflow: hidden;
}

.whiteWrapper {
  @extend .sheredWrapper;
  background-color: $bg-secondary;
}

.greyWrapper {
  @extend .sheredWrapper;
  background-color: $bg-primary;
}

.borderBottom {
  border-bottom: 1px solid $border-color;
}

.border {
  border: 1px solid $border-color;
  border-radius: $border-radius;
}

.formContainer {
  // border-radius: $border-radius;
  h2 {
    font-size: 1rem;
    padding-bottom: 0.625rem;
    border-bottom: 1px solid $border-color;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
}

.formGrey {
  background-color: $bg-primary;
}

.formWhite {
  background-color: $bg-secondary;
}

.twoColumns {
  display: flex;
  flex-direction: column;
  border: 1px solid $border-color;
  flex-grow: 1;
  // border-radius: $border-radius;
  :global {
    .first-column,
    .second-column {
      width: 100%;
      padding: 1rem;
    }
  }
}

.oneColumn {
  width: 100%;
  padding: 1rem 0;
  @media only screen and (min-width: 992px) {
    // :global {
    //   .tb-custom {
    //     width: calc(100vw - 18rem);
    //   }
    // }
  }
}
.width50 {
  width: 100%;
  @media only screen and (min-width: 992px) {
    width: 50%;
    border: 1px solid $border-color;
    padding: 1rem;
  }
}
.formGroup {
  //  padding: 0.6rem;
  // margin: 0.7rem 0.7rem 1rem 0.7rem;
  margin-bottom: 1rem;

  //  padding: 0.7rem 0;
  // background: $bg-primary;
  // border-bottom: 1px solid $border-color;
  // border-radius: $border-radius;
  h4 {
    color: $color-warning;
    margin-bottom: 1rem;
    border-bottom: 1px solid $border-color;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    text-transform: uppercase;
  }
}

@media only screen and (min-width: 992px) {
  .twoColumns {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $border-color;
    min-height: 100%;

    :global {
      // .first-column {
      //   padding: 1rem 1rem 1rem 0;
      // }

      .second-column {
        // padding: 1rem 0rem 1rem 1rem;
        border-left: 1px solid $border-color;
      }
    }

    &.width5050 {
      :global {
        .first-column,
        .second-column {
          width: 50%;
        }
      }
    }

    &.width6050 {
      :global {
        .first-column {
          width: calc(60% - 1rem);
        }

        .second-column {
          width: calc(40% - 1rem);
        }
      }
    }
    &.width2070 {
      :global {
        .first-column {
          width: 25%;
        }

        .second-column {
          width: 75%;
        }
      }
    }
    &.width4060 {
      :global {
        .first-column {
          width: 40%;
        }
        .second-column {
          width: 60%;
        }
      }
    }
  }

  .width5050NoBorder {
    border-bottom: none;

    :global {
      .second-column {
        border-left: none;
      }
    }
  }

  .formRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.frTwoColumns {
      > div {
        width: 49%;
      }
    }

    &.frThreeColumns {
      > div {
        width: 32%;
      }
    }
  }
}
