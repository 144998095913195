@import 'styles/variables';
.wrapper {
  position: fixed;
  width: 100%;
  z-index: 111;
  top: 0;
}
.socialWeb {
  a {
    display: inline-block;
    height: auto;
    width: auto;
  }
  svg {
    fill: $color-light;
    width: 30px;
    height: 30px;
    opacity: 0.9;
    margin-right: 0.5rem;
  }
}
