@import 'styles/variables';

.defaultSwitch {
  label {
    color: $input-label;
    font-size: $input-label-size;
    padding-right: 1rem;
  }

  :global {
    .rc-switch {
      width: 2rem;
      height: 0.75rem;
      overflow: visible;
      background: $bg-light;
    }

    .rc-switch:after {
      width: 18px;
      height: 18px;
      top: -7px;
      left: -4px;
      border: 2px solid $bg-secondary;
      box-shadow: none;
      background-color: $lightGray1;
    }

    .rc-switch-checked {
      border: 1px solid $bg-light;
      background-color: $bg-light;
    }

    .rc-switch:focus {
      box-shadow: none;
      outline: none;
    }

    .rc-switch-checked:after {
      background: $bg-bright;
      left: 15px;
      border: 2px solid $bg-secondary;
      box-shadow: none;
    }

    .rc-switch:hover:after {
      transform: none;
    }
  }
}