//  border-radius
.br-xs {
  border-radius: $border-radius;
}

.br-50 {
  border-radius: 50%;
}

// width
.w-25 {
  width: 25%;
}

.w-33 {
  width: 33%;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

// background
.bg-p {
  background-color: $bg-primary;
}

.bg-s {
  background-color: $bg-secondary;
}

// color
.c-b {
  color: $color-bright;
}

.c-p {
  color: $color-primary;
}

.c-s {
  color: $color-secondary;
}

// Text align
.ta-c {
  text-align: center;
}

.ta-l {
  text-align: left;
}

.ta-r {
  text-align: right;
}

// Margin
.m-auto {
  margin: 0 auto;
}

.m-0 {
  margin: 0 !important;
}

.m-05 {
  margin: 0.25rem;
}

.m-1 {
  margin: 0.5rem;
}

.m-2 {
  margin: 1rem;
}

.m-3 {
  margin: 1.5rem;
}

.m-4 {
  margin: 2rem;
}

.m-5 {
  margin: 2.5rem;
}

.m-6 {
  margin: 3rem;
}

// Margin  bottom
.mb-0 {
  margin-bottom: 0;
}

.mb-05 {
  margin-bottom: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.mb-6 {
  margin-bottom: 3rem;
}

// Margin top
.mt-0 {
  margin-top: 0;
}

.mt-05 {
  margin-top: 0.25rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mb-5 {
  margin-top: 2.5rem;
}

.mb-6 {
  margin-top: 3rem;
}

// Margin left
.ml-0 {
  margin-left: 0;
}
.ml-05 {
  margin-left: 0.25rem;
}
.ml-1 {
  margin-left: 0.5rem;
}

.ml-2 {
  margin-left: 1rem;
}

.ml-3 {
  margin-left: 1.5rem;
}

.ml-4 {
  margin-left: 2rem;
}

.ml-5 {
  margin-left: 2.5rem;
}

.ml-6 {
  margin-left: 3rem;
}

// Margin right
.mr-0 {
  margin-right: 0;
}
.mr-05 {
  margin-right: 0.25rem;
}
.mr-1 {
  margin-right: 0.5rem;
}

.mr-2 {
  margin-right: 1rem;
}

.mr-3 {
  margin-right: 1.5rem;
}

.mr-4 {
  margin-right: 2rem;
}

.mr-5 {
  margin-right: 2.5rem;
}

.mr-6 {
  margin-right: 3rem;
}

// Margin left and right
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.mx-05 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.mx-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-3 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-4 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-5 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mx-6 {
  margin-left: 3rem;
  margin-right: 3rem;
}

// Margin top and bottom
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-05 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-6 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

//Padding
.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.5rem;
}

.p-2 {
  padding: 1rem;
}

.p-3 {
  padding: 1.5rem;
}

.p-4 {
  padding: 2rem;
}

.p-5 {
  padding: 2.5rem;
}

.p-6 {
  padding: 3rem;
}

// Padding bottom
.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.pb-5 {
  padding-bottom: 2.5rem;
}

.pb-6 {
  padding-bottom: 3rem;
}

// Padding top
.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.5rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pt-4 {
  padding-top: 2rem;
}

.pb-5 {
  padding-top: 2.5rem;
}

.pb-6 {
  padding-top: 3rem;
}

// Padding left
.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 0.5rem;
}

.pl-2 {
  padding-left: 1rem;
}

.pl-3 {
  padding-left: 1.5rem;
}

.pl-4 {
  padding-left: 2rem;
}

.pb-5 {
  padding-left: 2.5rem;
}

.pb-6 {
  padding-left: 3rem;
}

// Padding right
.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 0.5rem;
}

.pr-2 {
  padding-right: 1rem;
}

.pr-3 {
  padding-right: 1.5rem;
}

.pr-4 {
  padding-right: 2rem;
}

.pb-5 {
  padding-right: 2.5rem;
}

.pb-6 {
  padding-right: 3rem;
}

// Padding left and right
.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-3 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-6 {
  padding-left: 3rem;
  padding-right: 3rem;
}

// Padding top and bottom
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-6 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
