@import 'styles/variables';

.pagination-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
  .g-select {
    width: 100px;
    margin-left: 1rem;
  //   .selectGlobal__control{
  //       min-height: 20px;
  //       height: 20px;
  //   }
  //   .selectGlobal__indicator {
  //       padding: 0;
  //   }
  //   .selectGlobal__value-container ::after{
  //     height: 20px;
  //   }
   }
}

.pagination-component {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
 
  .rc-pagination-item {
    display: flex;
    align-items: center;
    background-color: $bg-secondary;
    justify-content: center;
    min-width: 20px;
    height: 20px;
    border-radius: 2px;
    border: none;
    margin-right: 10px;
    padding: 0 5px;

    a {
      color: $color-bright;
      font-size: small;
      font-weight: bold;
    }

    &:focus {
      outline: none;
      border-color: $color-bright;
    }

    &:hover {
      a {
        color: $color-bright;
        border-color: $color-bright;
      }
    }
  }

  .rc-pagination-next {
    border: none !important;
    outline: none;
  }

  .rc-pagination-prev {
    border: none !important;
    outline: none;
    margin-right: 16px;
  }

  .rc-pagination-item-active {
    background-color: $bg-primary;
    min-width: 20px;
    height: 20px;
    border-radius: 2px;
  }
}

.paginator-pagesize-selector {
  margin-left: 10px;
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}
