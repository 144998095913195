@import 'styles/variables';

.defaultPhone {
  position: relative;
  margin-bottom:$input-margin-bottom;
  label {
    font-size: $input-label-size;
    color: $input-label;
    display: block;
    padding-bottom: 0.31rem;
  }

  input {
    width: 100%;
  }

  :global {
    .form-control {
      height:$input-min-height;
    }

    .react-tel-input {
      input {
        width: 100%;
      }

      :disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

.preview {
  :global {
    .react-tel-input {
      .flag-dropdown {
        display: none;
      }

      input {
        border: none;
        border-bottom: 1px solid $border-color;
        font-weight: bold;
        padding-left: 0;
      }

      :disabled {
        opacity: 1;
      }
    }
  }
}