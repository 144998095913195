@import 'styles/variables';
.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  h2 {
    text-transform: uppercase;
  }
  .mobHeading {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
    border-bottom: 1px solid $border-color;
    > div {
      width: 25%;
      padding: 0.5rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      
    }
    h3{
      text-align: center;
      line-height: 1;
      font-size: 0.9rem;
      margin-top: 0.5rem;
     // color: $color-bright;
    }
    svg {
      width: 50px;
      height: 50px;
      box-sizing: border-box;
      border: 1px solid $border-color;
      padding: 1rem;
      border-radius: 50%;
      color: $color-bright;
    }
  }
}
