@import 'styles/variables';

.defaultInput {
  position: relative;

  label {
    font-size: $input-label-size;
    color: $input-label;
    display: block;
    padding-bottom: 0.31rem;
  }

  .input {
    padding: 0 0.625rem;
    height: $input-min-height;
    border: 1px solid $input-border;
    border-radius: $border-radius;
    font-size: 1rem;
    font-weight: 400;
    color: $input-text;
    background-color: $input-bg;
    background-clip: padding-box;
    display: block;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &::placeholder {
      color: $input-placeholder;
      font-size: 0.8rem;
    }
  }
}

.inputLabelNext {
  @extend .defaultInput;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  label {
    padding-right: 1rem;
    margin-bottom: 0px;
  }

  input {
    flex-grow: 1;
    width: initial !important;
  }
}
