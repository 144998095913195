@import 'styles/variables';

.wrapper {
  .topNav {
    display: flex;
    background-color: $bg-bright;
    padding: 0.5rem 1rem;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    box-sizing: border-box;
    position: relative;
    .logo img {
      height: 30px;
      position: relative;
      top: 2px;
    }
    a,
    svg {
      text-transform: uppercase;
      cursor: pointer;
    }
    .right {
      svg {
        margin-left: 1rem;
        font-size: 2rem;
        color: $color-light;
      }
    }
    .left {
      display: flex;
      align-items: center;
      svg {
        margin-right: 1rem;
        font-size: 1.6rem;
        color: $color-light;
      }
    }
    .btnGroup {
      display: flex;
      align-items: center;
    }
    .dropdownUser {
      position: absolute;
      right: 0;
      top: 50px;
      background-color: $bg-secondary;
      box-shadow: 0px 3px 6px 0px #c3c2c2;
      padding: 1rem;
      li {
        padding-bottom: 1rem;
      }
      a {
        font-size: 1rem;
        text-transform: initial;
      }
    }
  }

  .leftNav {
    position: absolute;
    left: 0;
    height: 100vh;
    background-color: $bg-secondary;
    z-index: 1;
    box-shadow: 0px 3px 6px 0px #c3c2c2;
    width: 180px;
    :global {
      .fa-xmark {
        position: absolute;
        right: 0rem;
        // color: $color-bright;
        font-size: 2rem;
        color: $color-bright;
        width: 20px;
        height: 20px;
        background: $bg-secondary;
      }
    }
    li {
      padding: 0.3rem 0;
    }
    .info {
      background-color: $greenLight2;
      //height: 50px;
      padding: 1rem;
      box-sizing: border-box;
      img {
        height: 22px;
        margin-bottom: 0.7rem;
      }
      h5 {
        font-size: 1rem;
      }
      h6 {
        font-size: 0.8rem;
        color: $color-bright;
      }
    }
    .links {
      padding: 1rem;
    }
    .listGroup {
      padding: 0.5rem 0;
      border-bottom: 1px solid $border-color;
    }
    .btnGroups {
    }
  }
}
