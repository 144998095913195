@import 'styles/variables';

.customOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(229, 229, 229, 0.6);
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  backdrop-filter: blur(10px);
  left: 0;
  height: 100%;
}

.customModal {
  position: absolute;
  top: 5rem;
  margin-bottom: 50px;
  border: none;
  background: $bg-secondary;
  border-radius: $border-radius;
  outline: none;
  z-index: 99999;
  width: 85%;
  margin-bottom: 2rem;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
}

.customModalTransparent {
  @extend .customModal;
  background: transparent;
}

.sm {
  max-width: 300px;
}

.md {
  max-width: 500px;
}

.lg {
  max-width: 700px;
}

.lmg {
  max-width: 900px;
}

.xlg {
  max-width: 1200px;
}
.w-100 {
  max-width: 98%;
  width: 100%;
}
.vh-100 {
  max-width: 95%;
  width: auto;
  max-height: 95vh;
  height: auto;
  top: 0rem;
  margin: 2.5vh;
  min-width: 90%;

  .modalBody {
    height: 100%;
  }
}

.modalBody {
  padding: 1.5rem 1.25rem;
  position: relative;
}

.close {
  position: absolute;
  right: 0.3rem;
  top: 0.2rem;
  cursor: pointer;
  z-index: 99999;

  svg {
    font-size: 1.3rem;
    color: $color-secondary;
  }
}
.modalYesNo {
  .title {
    font-weight: normal;
    text-transform: initial;
    font-size: 1rem;
    text-align: center;
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin: 1rem 0.5rem 00.5rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .close {
    right: 0.3rem;
    top: 0.3rem;

    svg {
      font-size: 1.5rem;
    }
  }

  .modalBody {
    padding: 1.5rem;
  }

  .vh-100 {
    min-width: auto;
    height: 100%;
  }
}
