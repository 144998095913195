.public-wrapper {
  padding: 1rem;
  margin: 3.75rem auto 0 auto;
  height: 100%;
  width: 100%;
}

.container-fluid-wrapper {
  width: 100%;
  padding: 0px;
  margin: 0 auto;
}

.auth-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 3.75rem;

  // aside {
  //   background: $bg-secondary;
  //   margin-bottom: 1rem;
  //   padding: 0;
  //   position: fixed;
  //   width: 100%;
  //   z-index: 3;
  // }

  section {
    flex-grow: 1;
    background: $bg-secondary;
    padding: 1rem;
    margin: 4rem 1rem;
    border-radius: $border-radius;
  }
}

@media screen and (min-width: 768px) {
  .public-wrapper {
    padding: 2rem;
  }
  .auth-wrapper {
    section {
      margin: 4rem 2rem 2rem 2rem;
      height: 100%;
      width: auto;
      background: $bg-secondary;

    }

  }
}

@media screen and (min-width: 992px) {
  .public-wrapper {
    padding: 2rem 0;
    max-width: 1160px;
  }

  .auth-wrapper {
    display: flex;
    flex-direction: row;
    padding: 0rem;
    min-height: calc(100% - 3.75rem);

    section {
      margin: 4rem 1.5rem 1.5rem 1.5rem;
      background: $bg-secondary;
      min-height: calc(100vh - 6.75rem)
    }

    // aside {
    //   position: relative;
    //   width: 14rem;
    //   background: $bg-secondary;
    //   padding: 0;
    //   margin-bottom: 0;
    //   position: fixed;
    //   top: 3.75rem;
    //   height: 100%;
    //   border-right: 1px solid $border-color;
    //   z-index: 2;
    // }
  }
}