@import 'styles/variables';
.firstBlock {
  background: $bg-bright;
}
.secondBlock {
  background: $bg-secondary;
  border-bottom: 1px solid $border-color;
}
.bottomNavbar {
  height: 3.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  margin: 0 auto;
  width: 100%;
  z-index: 9;
 // border-bottom: 1px solid $border-color;
  max-width: 1200px;
  .content {
    max-width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  img {
    height: 40px;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    li {
      padding-left: 1.5rem;
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  :global {
    .active {
      color: $link-hover;
    }
  }

  h3 {
    margin: 0;
  }

  .dropdown {
    display: flex;
    flex-direction: column;
    width: 200px;
    background: $bg-secondary;
    border: 1px solid $border-color;
    padding: 0.62rem;
    box-sizing: border-box;
    border-radius: $border-radius;
    align-items: center;
    z-index: 2;

    ul {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;

      li {
        width: 100%;
        padding: 0.31rem 0;
        border-bottom: 1px solid $border-color;
        align-items: flex-start;
        display: flex;

        a {
          margin-left: 0.31rem;
        }
      }
    }
  }
}

.topNavWrapper {
  height: 3.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  max-width: 1200px;
  margin: 0 auto;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    li {
      padding-left: 1.5rem;
      display: flex;
      align-items: center;
      height: 100%;
      a {
        color: $color-light;
        text-transform: uppercase;
      }
    }
  }

  .topDropdown {
    position: relative;
  }

  .dropContent {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    :global {
      .fa-circle-user {
        font-size: 1.5rem;
        color: $color-light;
        margin-left: 0.62rem;
      }
    }
    span {
      color: $color-light;
      text-transform: uppercase;
    }
    .dropdown {
      display: none;
    }
    &:hover {
      .dropdown {
        display: flex;
        position: absolute;
        left: 1.5rem;
        top: 3.7rem;
        align-items: flex-start;
        flex-direction: column;
        width: 150px;
        background: $bg-secondary;
        box-shadow: 0px 3px 6px 0px #c3c2c2;
        border: 1px solid $border-color;
        padding: 0.62rem;
        box-sizing: border-box;
        border-radius: $border-radius;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        z-index: 2;
        height: auto;
        li {
          width: 100%;
          padding: 0.31rem 0;
          border-bottom: 1px solid $border-color;
          align-items: flex-start;
          display: flex;

          a {
            margin-left: 0.31rem;
            color: $color-bright
          }
        }
      }
      .dropUser {
        right: 0;
        left: initial;
      }
    }
  }
  .btnGroup{
    display: flex;
  }
}

