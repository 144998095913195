@import 'styles/variables';
.wrapper{
    h6 {
        background: $bg-error;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: normal;
        font-size: 0.75rem;
        padding: 0.5rem;
        margin: 0.3rem 0;
        border-radius: $border-radius;
        svg{
          cursor: pointer;
          font-size: 1rem;
        }
      }   
      &.defaultSmall{
        padding: 0.3rem;
        margin: 0.3rem 0;
        border-radius: 0.25rem;
        letter-spacing: 1px;
      }
}