@import 'styles/variables';

.defaultCheckbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .checkBox {
    position: relative;
  }
  .checkmark {
    position: absolute;
    opacity: 0;
  }

  .checkmark + label {
    position: relative;
    cursor: pointer;
    padding-top: 2px;
    margin: 2px 0;
    color: $input-label;
    font-weight: 300;
    left: 3px;
    font-size: $input-label-size;
  }

  .checkmark + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: $input-bg;
    border: 1px solid $input-border;
    border-radius: 4px;
    position: relative;
    top: -3px;
  }

  .checkmark:hover + label:before {
    background: $input-bg;
  }

  .checkmark:focus + label:before {
    box-shadow: none;
  }

  .checkmark:checked + label:before {
    background: $bg-secondary;
    border: 1px solid $bg-bright;
  }

  .checkmark:disabled + label {
    opacity: 0.6;
    cursor: auto;
    cursor: not-allowed;
  }

  .checkmark:disabled + label:before {
    box-shadow: none;
    background: $input-bg;
  }

  .checkmark:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 10px;
    background: $color-bright;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 $color-bright, 4px 0 0 $color-bright, 4px -2px 0 $color-bright, 4px -4px 0 $color-bright, 4px -6px 0 $color-bright,
      4px -8px 0 $color-bright;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
