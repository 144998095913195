@import 'styles/variables';

.btn {
  position: relative;
  font-size: 1rem;
  box-shadow: none;
  white-space: normal;
  padding: 0 1rem;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-weight: 500;
  min-height: $input-min-height;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  span {
    padding: 0 0.625rem;
  }
}

.iconLeft,
.iconRight {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 1rem;
  }
}

.iconRight {
  padding-right: auto;
  transition: transform 0.3s ease-in-out;
}

.iconLeft {
  padding-left: auto;
  transition: transform 0.3s ease-in-out;
}

.spinner {
  > div {
    display: flex;
    align-items: center;
    I svg {
      margin-left: 0.625rem;
    }
  }
}

.btnPrimary {
  @extend .btn;
  color: $btn-primary-color;
  background: $btn-primary-bg;
  border: 1px solid $btn-primary-border;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $btn-primary-hover;
    border-color: $btn-primary-hover;
    color: $btn-primary-hover-color;
  }
}

.btnSecondary {
  @extend .btn;
  color: $btn-secondary-color;
  background: $btn-secondary-bg;
  border: 1px solid $btn-secondary-border;
  transition: all 0.5s ease-in-out;

  &:hover {
    color: $btn-secondary-hover-color;
    background-color: $btn-secondary-hover;
  }
}
.btnWarning {
  @extend .btn;
  color: $btn-warn-color;
  background: $btn-warn-bg;
  border: 1px solid $btn-warn-border;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $btn-warn-hover;
    border-color: $btn-warn-hover;
    color: $btn-warn-hover-color;
  }
}
.btnLight {
  @extend .btn;
  color: $color-secondary;
  background: $bg-primary;
  border: 1px solid $bg-light;
  transition: all 0.5s ease-in-out;

  &:hover {
    color: $color-light;
    background-color: $mediumGray;
  }
}
.btnGray {
  @extend .btn;
  color: $btn-primary-color;
  background: #636464;
  border: 1px solid #636464;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #636464;
  }
}

.btnDanger {
  @extend .btn;
  color: $color-light;
  background: $redDark;
  border: 1px solid $redDark;
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}
