@import 'styles/variables';

.uploadMedia {
  .box {
    border: 2px dashed $bg-light;
    background-color: $bg-secondary;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 2rem;
    border-radius: $border-radius;

    &.dragActive {
      border: 2px dashed $bg-bright;
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    svg {
      font-size: 2rem;
      color: $color-secondary;
      margin-bottom: 0.5rem;
    }
  }

  input {
    display: none;
  }

  .header {
    margin: 1rem 0;
    text-align: left;
    p {
      font-size: 0.7rem;
    }
    h3 {
      font-size: 0.8rem;
    }
  }

  .images {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-rows: auto;
    justify-content: space-between;
    margin: 1rem 0;
    grid-gap: 1rem 0;
  }

  .img {
    background: $bg-primary;
    padding: 1rem;
    border-radius: $border-radius;
    position: relative;
    height: 9rem;
    border: 1px solid $border-color;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    svg {
      position: absolute;
      right: -5px;
      top: -5px;
      font-size: 1.7rem;
      color: $color-primary;
      cursor: pointer;
    }
  }

  @media only screen and (min-width: 992px) {
    .images {
      grid-template-columns: 32% 32% 32%;
    }
  }
}
